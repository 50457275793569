import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import Productivity from "../components/Solutions/Productivity/Productivity";

import Favicon from "../images/Favicon.png";

const ProductivityPage = () => {
    return(
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Productivity - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="keywords" content="first-party"/>
                <meta name="description"
                      content="DrivosityEDGE offers a productivity solution for your food delivery business with in-depth reporting features. Our real-time GPS tracking measures driver efficiency, customer feedback, and driver engagement to optimize performance. Schedule a demo now."/>
            </Helmet>
            <Layout component={<Productivity/>}/>
        </>
    )
}

export default ProductivityPage;
