import styled from "styled-components";

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: var(--text-color);
  
  & .paragraph {
    margin-bottom: 30px;
  }
  
  & .points {
    margin-bottom: 12px;
    line-height: 26px;
    padding-left: 14px;
  }
`
